import React from "react";
import "./topbar.css";
import { LogoutOutlined } from "@ant-design/icons"
import Cookies from "js-cookie";
import { Row, Col, message, Tooltip } from "antd";
import logo from "./travelex-logo-old.svg";
import {logoutUser} from "../../../api/logout"



class Topbar extends React.Component {

  logout = async (e) => {
    try {
  
        let body = JSON.stringify({
            code: Cookies.get('code')
        })
        console.log("Push logout :::", this.props);
        console.log("Logout event :::", e);
        console.log("Recycling url :::", `${process.env.REACT_APP_KYC_URL}`);
        console.log("Cookies code ::: ", body);
        const res = await logoutUser(body)
  
        if (res && res.message === "Token invalidated") {
            //clear cookies and push to login
            Cookies.remove('expiresIn');
            Cookies.remove('code');
            Cookies.remove('code_challenge');
            Cookies.remove('code_verifier');
            console.log("code :::", Cookies.get('code'));
            console.log("Cookies code After ::: ", body);
            // this.props.history.push("/");
            window.location.href = `${process.env.REACT_APP_KYC_URL}`;
        } else {
            message.error(res.message || 'Error Occured');
        }
    } catch (err) {
        console.log('Error Occured', err);
    }
  }

  render() {
    return (
      <Row className="top-bar">
        <Col span={4}>
          <div className="image">
            <img
              alt=""
              src={logo}
              width="190em"
              height="70em"
              className="d-inline-block align-top"
            />
          </div>
        </Col>
        <Col span={16}>
          <Row justify="center" >
            <h4 style={{ paddingTop: "0.7em", fontSize:"1.5em", fontWeight: "600" }}>
              Automated ID Hub
            </h4>
          </Row>
        </Col>
        <Col span={3}/>
        <Col span={1}>
          <Tooltip title={"Logout"}><LogoutOutlined style={{float:"right", paddingTop:"0.4em", height:"1em", width:"1em", fontSize: '2em'}} onClick={() => this.logout()} alt="Logout" /></Tooltip>
        </Col>
      </Row>
    );
  }
}

export default Topbar;
