// import { Row, Col, Button } from "react-bootstrap";
import { Button } from "antd";
import React, { useState, useContext } from "react";

import "antd/dist/antd.css";
import Highlighter from "react-highlight-words";

import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import "./ResultsDataTable.css"
import axios from "axios";

import {
    Table,
    Input,
    InputNumber,
    Popconfirm,
    Form,
    Typography,
    Space,
    Spin,
    Tooltip,
    Card,
  } from "antd";
// import { Header } from "antd/lib/layout/layout";
// import './table.css'

// import { FilterContext } from "../../../context/FilterContext";

export const ResultsDataTable = (props) => {

    // const { filterState } = useContext(FilterContext);
    // console.log("ResultsDataTable :: ", props);
    let transformedData, data, columns, currency, totalRecords;
    const antIcon = <LoadingOutlined style={{ fontSize: 20, fontWeight: "bold" }} spin />
    
    if (props.data) {
        data = Array.isArray(props.data)?(props.data):([props.data]);
        columns = ["CategoryType", "CategoryText", "NumCatDataSources", "NumCatDataItems", "StartDateOldestCat"];
        columns = columns.filter(item => item !== "index" &&item !== "Unnamed: 0")
        // totalRecords = filterState.totalRecords;
        // currency = filterState.filters;
        // console.log(currency);
        transformedData = data.map((record,index) => {
            record["key"] = index;
            // console.log("Data :::", record, '----', record["key"])
            return record;
        });
    }
    
    let [searchState, setSearchState] = useState("");
    searchState = {
      searchText: "",
      searchedColumn: "",
    };

    let searchInput;

    let getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchState.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchState.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    }

    function handleReset(clearFilters) {
      clearFilters();
      setSearchState({ searchText: "" });
    }

    //Merge array cells
    // const createNewArr=(data)=>{
    //   // console.log(data)
    //   return data.reduce((result, item) => {
    //   //First, take the name field as a new array result
    //       if (result.indexOf(item.CCY) < 0) {
    //           result.push(item.CCY)
    //       }
    //       return result
    //   }, []).reduce((result, name) => {
    //   //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
    //     const children = data.filter(item => item.CCY === name);
    //     // console.log(children)
    //     // console.log(name)
    //     result = result.concat(
    //       children.map((item, index) => ({
    //         ...item,
    //         rowSpan: index === 0 ? children.length : 0,//Add the first row of data to the rowSpan field
    //       }))
    //     )
    //     return result;
    //   }, [])
    // }

    let tbColumnsList;

    if (columns && columns.length > 0) {
        tbColumnsList = columns.map((tbcolumn) => {
            return{
                title: (tbcolumn[0].toUpperCase() + tbcolumn.slice(1)).replace(/[A-Z]/g, ' $&').trim(),
                dataIndex: tbcolumn,
                // width: 90,
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                render(text, record) {
                  return {
                    props: {
                    //   style: { background: "#e9f1f9"}
                    },
                    children: <div>{text}</div>
                  };
                }
                
            };     

        });
    }

    return(
        <>
        
            <Table
                bordered= "true"
                dataSource={transformedData}
                columns={tbColumnsList}
                size="small"
                width="auto"
                // scroll={{ x: 3500, y: 500 }} 
                scroll={{ x: "max-content"}} 
                pagination={false}
                      
            />
        </>
    );
};