import React from "react";
import { Row, Col } from "antd";
import "../Footer/footer.css";

export const Footer = () => {
  let year = new Date().getFullYear()
  return (
    <div className="footer">
      <span>
        Copyright © {year} Travelex India Limited and its licensors. All rights
        reserved.
      </span>
    </div>
  );
}

export default Footer;