import React, { useContext, useEffect, useState } from "react";
// import { Button } from "react-bootstrap";

import "antd/dist/antd.css";
import { Row, Col } from "antd";
// import { message } from "antd"
import "bootstrap/dist/css/bootstrap.min.css";

import { } from "react-bootstrap";
import { ResultsDataTable } from "./ResultsDataTable";
import { QueryContext } from "../../../context/QueryContext";
// import swal from 'sweetalert';
// import { useHistory } from "react-router-dom";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
// const { Option } = Select;

let authenticated = { 
    borderWidth: "1px", 
    borderStyle: "solid", 
    borderColor: "#2cbf31", 
    backgroundColor:"#2cbf31", 
    padding: "10px 10px 10px 10px", 
    marginTop: "10px", 
    color: "white",
    fontWeight: "bolder",
    fontSize: "20px"
 };

 let refer = { 
    borderWidth: "1px", 
    borderStyle: "solid", 
    borderColor: "#ffe814", 
    backgroundColor:"#ffe814", 
    padding: "10px 10px 10px 10px", 
    marginTop: "10px", 
    color: "white",
    fontWeight: "bolder",
    fontSize: "20px"
 };

 let not_authenticated = { 
    borderWidth: "1px", 
    borderStyle: "solid", 
    borderColor: "#f0190a", 
    backgroundColor:"#f0190a", 
    padding: "10px 10px 10px 10px", 
    marginTop: "10px", 
    color: "white",
    fontWeight: "bolder",
    fontSize: "18px"
 };

export const Results = (props) => {

    const { queryState, queryDispatch } = useContext(QueryContext);
    const [queryResult, setQueryResult] = useState(null);
    
    // console.log("Reports");
    // let result = "authenticated";
    // console.log("response result :: ", queryState.response);

    useEffect(() => {
        // console.log("Results use effect :: ", queryState.response);
        if(!queryResult && queryState.response){
            // console.log("Results test 1 :: ", queryState.response.message);
            let response = JSON.parse(queryState.response)
            setQueryResult(response.finalResponse ? (response.finalResponse):(response));
        }
    });

    return (
        <React.Fragment>
            <div style={{ minHeight: '78vh', backgroundColor: "rgb(249, 251, 255)" }}>
                {queryResult?(
                    queryResult.Decision || queryResult.DecisionCode || queryResult.DecisionDescription || queryResult.Reference || queryResult.ApiResponse?(
                        queryResult.DecisionCode!=="ER00" && queryResult.DecisionCode!=="1003" && queryResult.ApiResponse!==""?(
                            <React.Fragment>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col>
                                        
                                    </Col>
                                </Row>

                                <div
                                    style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}
                                >
                                    <Row>
                                        <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                            Identity Hub Reference Number :
                                        </Col>
                                        <Col span={3} style={{ color: "grey" }}>
                                            {queryResult && queryResult.Reference ? (queryResult.Reference) : ""}
                                        </Col>
                                        <Col span={1}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                            Rule triggered :
                                        </Col>
                                        <Col span={3} style={{ color: "grey" }}>
                                            {/* A1 */}
                                            {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.RuleTriggered ? (queryResult.ApiResponse.RuleTriggered) : ("")}
                                        </Col>
                                        <Col span={1}>

                                        </Col>
                                    </Row>

                                    <Row>
                                        {(() => {                            
                                            if(queryResult && queryResult.DecisionCode && queryResult.DecisionCode === "Authenticated"){
                                                return(
                                                    <>
                                                        <Col span={6} 
                                                            style={authenticated}
                                                        >
                                                            <CheckCircleFilled style={{height:"30px", width:"35px", fontSize: '28px'}} /> <span style={{paddingLeft: "20px"}}>Authenticated</span>
                                                        </Col>
                                                        <Col span={18} style={{ padding: "25px 10px 10px 10px",color: "grey" }}>
                                                            <span>
                                                                {/* The applicant has been authenticated to your required minimum level based on the rules implemented in the selected query. */}
                                                                {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.AuthenticationText ? (queryResult.ApiResponse.AuthenticationText) : ""}
                                                            </span>
                                                        </Col>
                                                    </>
                                                )
                                            }else if(queryResult && queryResult.DecisionCode && queryResult.DecisionCode === "Refer"){
                                                return(
                                                    <>
                                                        <Col span={6} 
                                                            style={refer}
                                                        >
                                                            <CloseCircleFilled style={{height:"30px", width:"35px", fontSize: '28px'}} /> <span style={{paddingLeft: "20px"}}>Refer</span>
                                                        </Col>
                                                        <Col span={18} style={{ padding: "25px 10px 10px 10px",color: "grey" }}>
                                                            <span>
                                                                {/* Caution. One or more conditions have been returned that apply to this request based on the rules implemented in the selected query. */}
                                                                {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.AuthenticationText ? (queryResult.ApiResponse.AuthenticationText) : ""}
                                                            </span>
                                                        </Col>
                                                    </>
                                                )
                                            }else if(queryResult && queryResult.DecisionCode && queryResult.DecisionCode === "Not Authenticated"){
                                                return(
                                                    <>
                                                        <Col span={6} 
                                                            style={not_authenticated}
                                                        >
                                                            <CloseCircleFilled style={{height:"30px", width:"35px", fontSize: '28px'}} /> <span style={{paddingLeft: "20px"}}>Not authenticated</span>
                                                        </Col>
                                                        <Col span={18} style={{ padding: "25px 10px 10px 10px",color: "grey" }}>
                                                            <span>
                                                                {/* The applicant has not been authenticated to your required minimum level based on the rules implemented in the selected query. You should seek other proofs of identity before dealing with this applicant. */}
                                                                {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.AuthenticationText ? (queryResult.ApiResponse.AuthenticationText) : ""}
                                                            </span>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            
                                        })()}
                                    </Row>
                                </div>
                                <div
                                    style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px", marginTop: "20px" }}
                                >
                                    <Row
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <Col span={4} style={{ fontWeight: 600 }}>
                                            Authentication Index:
                                        </Col>
                                        <Col
                                            
                                            style={{ 
                                                borderWidth: "1px", 
                                                borderStyle: "solid", 
                                                borderColor: "#2cbf31", 
                                                backgroundColor:"#2cbf31", 
                                                padding: "2px 3px 2px 3px",
                                                color: "white",
                                            }}
                                        >   
                                            {/* 25 */}
                                            {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.authenticationIndex ? (queryResult.ApiResponse.authenticationIndex) : ""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                            Validation Score:
                                        </Col>
                                        <Col span={3} style={{ color: "grey" }}>
                                            {/* +0100 */}
                                            {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.ValidationScore ? (queryResult.ApiResponse.ValidationScore) : ""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                            Verification Score:
                                        </Col>
                                        <Col span={3} style={{ color: "grey" }}>
                                            {/* +0969 */}
                                            {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.VerificationScore ? (queryResult.ApiResponse.VerificationScore): ""}
                                        </Col>
                                    </Row>
                                    {(queryResult && queryResult.Decision && queryResult.Decision==="Reject")?(
                                        <React.Fragment>
                                            <Row>
                                                <Col>
                                                    {(queryResult && queryResult.ApiResponse && queryResult.ApiResponse.hasOwnProperty('EIAResults') && queryResult.ApiResponse.EIAResults.hasOwnProperty('IDandLocDataAtCL') && queryResult.ApiResponse.EIAResults.IDandLocDataAtCL.hasOwnProperty('CategoryData') && queryResult.ApiResponse.EIAResults.IDandLocDataAtCL.CategoryData.length!==0) ? (
                                                            <ResultsDataTable data={queryResult.ApiResponse.EIAResults.IDandLocDataAtCL.CategoryData}/>
                                                    ): (<br/>)}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                                    Age Details:
                                                </Col>
                                                <Col span={12} style={{ color: "grey" }}>
                                                    {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.AgeDobMatchDescription ? (queryResult.ApiResponse.AgeDobMatchDescription):([])}
                                                    <br/>
                                                    {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.AgeRangeDescription ? (queryResult.ApiResponse.AgeRangeDescription):([])}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                                    Telephone Directory Check:
                                                </Col>
                                                <Col span={12} style={{ color: "grey" }}>
                                                    {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.hasOwnProperty('TelephoneResults') && queryResult.ApiResponse.TelephoneResults.hasOwnProperty('HomeTelephoneNumber') ? 
                                                    (queryResult.ApiResponse.TelephoneResults.HomeTelephoneNumber):([])}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={5} style={{ color: "grey", fontWeight: 600 }}>
                                                    Alert Code/Description:
                                                </Col>
                                                <Col span={12} style={{ color: "grey" }}>
                                                    {queryResult && queryResult.ApiResponse && queryResult.ApiResponse.hasOwnProperty('EIAResults') && queryResult.ApiResponse.EIAResults.hasOwnProperty('ReturnedHRP') ? (
                                                        Array.isArray(queryResult.ApiResponse.EIAResults.ReturnedHRP)?(
                                                            <React.Fragment>
                                                                {queryResult.ApiResponse.EIAResults.ReturnedHRP.map((returnedHrp)=>
                                                                    returnedHrp.hasOwnProperty('HighRiskPolRuleID') && returnedHrp.hasOwnProperty('HighRiskPolRuleText')?(
                                                                        <li>{returnedHrp.HighRiskPolRuleID+" - "+returnedHrp.HighRiskPolRuleText}</li>
                                                                        ):([])
                                                                )}
                                                            </React.Fragment>
                                                        ):(
                                                            queryResult.ApiResponse.EIAResults.ReturnedHRP.hasOwnProperty('HighRiskPolRuleID') && queryResult.ApiResponse.EIAResults.ReturnedHRP.hasOwnProperty('HighRiskPolRuleText')?(
                                                                queryResult.ApiResponse.EIAResults.ReturnedHRP.HighRiskPolRuleID+" - "+queryResult.ApiResponse.EIAResults.ReturnedHRP.HighRiskPolRuleText
                                                            ):('')
                                                        )
                                                    ):([])}
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ):([])}
                                </div>
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                                <Row style={{ marginBottom: "10px" }}></Row>
                                {queryResult.ApiResponse===""||queryResult.Reference===""?(
                                    queryResult.DecisionCode==="1003"?(
                                    <div style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}>
                                        <Row style={{ color: "grey", fontWeight: 600, paddingBottom:'10px' }}>
                                            {queryResult.DecisionDescription}
                                        </Row>
                                    </div>
                                ):(queryResult.DecisionCode==="ER00"?(
                                    <div style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}>
                                        <Row style={{ color: "grey", fontWeight: 600, paddingBottom:'10px' }}>
                                            We couldn't find any result
                                        </Row>
                                        <Row style={{ color: "grey"}}>
                                            <ul>
                                                <li key="row 1">Please enter valid input data</li>
                                                <li key="row 2">Check your spellings</li>
                                            </ul>
                                        </Row>
                                    </div>
                                ):(
                                    <div style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}>
                                        <Row style={{ color: "grey", fontWeight: 600, paddingBottom:'10px' }}>
                                            Api response is not available
                                        </Row>
                                        <Row style={{ color: "grey"}}>

                                        </Row>
                                    </div>
                                ))
                                ):([])}
                            </React.Fragment>
                        )
                    ):(
                        <div style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}>
                            <Row style={{ color: "grey", fontWeight: 600, paddingBottom:'10px' }}>
                                We couldn't find any result
                            </Row>
                        </div>
                    )
                ):([])}
            </div>
        </React.Fragment>
    )
}

export default Results;