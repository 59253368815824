/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Row, Col, DatePicker, Space, Button, Table, Spin, Checkbox, Input } from "antd";
import { Alert, message } from 'antd';
import { DownloadOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
// eslint-disable-next-line
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { QueryContext } from "../../../context/QueryContext";
import XLSX from "xlsx"
import './Report.css'
import moment from 'moment';
import TreeMenu from 'react-simple-tree-menu'
import './ReportTree.css';

const dateFormat = 'YYYY-MM-DD HH:mm';

function Summary() {
    const { queryState, queryDispatch } = useContext(QueryContext);
    const searchInput = useRef(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [notEkycOrders, setNotEkycOrders] = useState(0);
    const [ekycOrders, setEkycOrders] = useState(0);
    const [thresholdOrders, setThresholdOrders] = useState(0);
    const [bypassedOrders, setBypassedOrders] = useState(0);
    const [ordersPassed, setOrdersPassed] = useState(0);
    const [ordersFailed, setOrdersFailed] = useState(0);
    const [summaryFilter, setSummaryFilter] = useState(false); 
    const [reportdatanew, setReportDataNew] = useState([]);
    const [filteredRecordsCount, setFilteredRecordsCount] = useState(0);

    useEffect(async () => {

        if (queryState.flattenedData !== undefined) {
            setTotalOrders(queryState.totalRecordsCount);
            getSummary();
            setReportDataNew(dataSource());
        }
        
    }, [queryState.totalRecordsCount, queryState.flattenedData])

    const treeData = [
        {
            key: '1',
            label: 'Order Placed ('+totalOrders+")",
            nodes: [
                {
                    key: '2',
                    label: 'Sent for eKyc ('+ekycOrders+')',
                    nodes: [
                        {
                            key: '4',
                            label: "Passed ("+ordersPassed+")",
                            nodes: [] // you can remove the nodes property or leave it as an empty array
                        },
                        {
                            key: '5',
                            label: 'Failed ('+ordersFailed+')',
                            nodes: [] // you can remove the nodes property or leave it as an empty array
                        },
                    ],
                },
                {
                    key: '3',
                    label: 'Not Sent for eKyc ('+notEkycOrders+')',
                    nodes: [
                        {
                            key: '6',
                            label: 'Below Threshold ('+thresholdOrders+')',
                            nodes: [] // you can remove the nodes property or leave it as an empty array
                        },
                        {
                            key: '7',
                            label: 'Bypassed ('+bypassedOrders+')',
                            nodes: [] // you can remove the nodes property or leave it as an empty array
                        },
                    ],
                },
            ],
        },
    ];

    const filterRecords = (record) => {

        record = record.map((record,index)=>{
            let keys = {}
            keys['key'] = index
            queryState.tableColumnKeys.map((key)=>{
            if(typeof(key)==="string"){
                if(key==='ReferenceNumber'){
                keys[key] = record[key]===undefined?("NOREF0000000"):(record[key])
                }
                else if(key==='RuleTriggered'){
                keys[key] = record[key]===undefined?("No Rule"):(record[key])
                }
                else if(key==='transactionDate'){
                keys[key] = moment(record[key]).utcOffset(0).format('YYYY-MM-DD HH:mm:ss.SS')
                }
                else{
                keys[key] = record[key]===undefined||record[key]===""?("NA"):(record[key])
                }
            }
            if(typeof(key)==="object"){
                Object.values(key)[0].map((innerkey)=>{
                keys[innerkey] = record[innerkey]===undefined||record[innerkey]===""?("NA"):(record[innerkey])
                })
            }
            })
            return keys
        })

        return record;
    };

    const onFilterClicked = (key) => {
        let filteredReportData = []

        setReportDataNew([]);
        setSummaryFilter(true);

        switch(key.split("/").pop()) {
            case "1":
                // console.log("Order Placed");
                if (queryState.flattenedData !== undefined) {
                    setReportDataNew(dataSource());
                    setFilteredRecordsCount(queryState.totalRecordsCount);
                }
                break;
            case "2":
                // console.log("Orders Sent for eKyc")
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        !data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD")
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            case "3":
                // console.log("Orders not Sent for eKyc")
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        data.DecisionDescription.includes("BYPASSED") || data.DecisionDescription.includes("LESS THAN THRESHOLD")
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            case "4":
                // console.log("Passed");
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        data.Decision.includes("Pass") && (!data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD"))
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            case "5":
                // console.log("failed")
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        data.Decision.includes("Reject") && (!data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD"))
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            case "6":
                // console.log("Below Threshold")
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        data.DecisionDescription.includes("LESS THAN THRESHOLD")
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            case "7":
                // console.log("Bypassed")
                if (queryState.flattenedData !== undefined) {
                    filteredReportData = queryState.flattenedData.filter(data => 
                        data.DecisionDescription.includes("BYPASSED")
                    )
                    setReportDataNew(filterRecords(filteredReportData));
                    setFilteredRecordsCount(filteredReportData.length);
                }
                break;
            default:
                break;
        }
        

    };

    const flushSummary = () => {
        setNotEkycOrders(0);
        setBypassedOrders(0);
        setThresholdOrders(0);
        setEkycOrders(0);
        setOrdersPassed(0);
        setOrdersFailed(0);
        setSummaryFilter(false);
        setFilteredRecordsCount(0);
    };

    const getSummary = () => {
        flushSummary();
        let notEkycCount = 0;
        let lthCount = 0;
        let bypassedCount = 0;
        let orderPassedCount = 0;
        let orderFailedCount = 0;
        let ekycCount = 0;
        
        if (queryState.flattenedData !== undefined) {
            queryState.flattenedData.filter(data => {
                if (data.DecisionDescription.includes("BYPASSED") || data.DecisionDescription.includes("LESS THAN THRESHOLD")) {
                    notEkycCount = notEkycCount + 1;
                    setNotEkycOrders(notEkycCount);

                    if (data.DecisionDescription.includes("BYPASSED")) {
                        bypassedCount = bypassedCount + 1;
                        setBypassedOrders(bypassedCount);
                    } else if (data.DecisionDescription.includes("LESS THAN THRESHOLD")) {
                        lthCount = lthCount + 1;
                        setThresholdOrders(lthCount);
                    }
                }

                if (!data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD")) {
                    ekycCount = ekycCount + 1;
                    setEkycOrders(ekycCount);
                    if (data.Decision.includes("Pass") && (!data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD"))) {
                        orderPassedCount = orderPassedCount + 1;
                        setOrdersPassed(orderPassedCount);
                    }
                    if (data.Decision.includes("Reject") && (!data.DecisionDescription.includes("BYPASSED") && !data.DecisionDescription.includes("LESS THAN THRESHOLD"))) {
                        
                        orderFailedCount = orderFailedCount + 1;
                        setOrdersFailed(orderFailedCount);
                    }
                }
            })
        }
    }


    function toDate(e) {
        if (!e) {
            queryDispatch({ type: "UPDATE_TO_DATE", value: e })
        }
        if (e !== null) {
            queryDispatch({ type: "UPDATE_TO_DATE", value: e.toISOString() })
        }
    }

    function fromDate(e) {
        if (!e) {
            queryDispatch({ type: "UPDATE_FROM_DATE", value: e })
        }
        if (e !== null) {
            queryDispatch({ type: "UPDATE_FROM_DATE", value: e.toISOString() })
        }
    }

    function submitReportNew(e) {

        e.preventDefault();
        if (queryState.toDate !== undefined && queryState.toDate !== null && queryState.fromDate !== undefined && queryState.fromDate !== null) {
            queryDispatch({ type: "FETCH_REPORT_DATA_HANDLE", filterState: queryState, dispatch: queryDispatch })
        }
        else {
            message.error("Please Select Date")
        }
    }

    function entireReportDetails(e) {
        queryDispatch({ type: "UPDATE_ENTIRE_REPORT_DETAIL_CHECKED", value: e.target.checked })
    }

    function exportReportData() {
        const fileName = "Report-" + moment(queryState.fromDate, "YYYY-MM-DD").format("DDMMYYYY") + "-" + moment(queryState.toDate, "YYYY-MM-DD").format("DDMMYYYY") + ".xlsx"
        if (queryState.isReportData) {
            const exportdata = (queryState.isFilteredReportData ? (queryState.filteredReportData) : (queryState.flattenedData)).map((record) => {
                let keys = {}
                queryState.exportColumnslist.map((key) => {
                    if (key === 'ReferenceNumber') {
                        keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key] === undefined || record[key] === '' ? ("NOREF0000000") : (record[key])
                    }
                    else if (key === 'RuleTriggered') {
                        keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key] === undefined || record[key] === '' ? ("No Rule") : (record[key])
                    }
                    else if (key === "transactionDate") {
                        keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = moment(record[key], "YYYY-MM-DDTHH:mm:ssZ").utcOffset(0).format("YYYY-MM-DD HH:mm:ss")
                    }
                    else {
                        keys[(key[0].toUpperCase() + key.slice(1)).replace(/[A-Z]/g, ' $&').trim()] = record[key]
                    }
                })
                return keys
            })
            var longest = queryState.exportColumnslist.map((record) => {
                var recrd = (record[0].toUpperCase() + record.slice(1)).replace(/[A-Z]/g, ' $&').trim()
                var long
                exportdata.map((cur) => { if (cur[recrd] === undefined) { } else if (long === undefined || long[recrd].length < cur[recrd].length) { long = cur } else { } });
                const width = (long === undefined || long[recrd] === undefined) ? (recrd.length) : (
                    (long[recrd].length >= recrd.length) ? (long[recrd].length) : (recrd.length))
                return { "width": width }
            })

            let headers = queryState.exportColumnslist.map((record) => {
                return (record[0].toUpperCase() + record.slice(1)).replace(/[A-Z]/g, ' $&').trim()
            })

            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.json_to_sheet(exportdata, { header: headers });
            ws['!cols'] = longest
            XLSX.utils.book_append_sheet(wb, ws, 'REPORT')
            XLSX.writeFile(wb, fileName)
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const onChange = (pagination, filters, sorter, extra) => {
        if (extra && extra.currentDataSource) {
            queryDispatch({ type: "UPDATE_FILTERED_REPORT_DATA", value: extra.currentDataSource })
        }
    };

    const crushColumnObj = (obj = {}, ans = []) => Object.keys(obj || {}).reduce((acc, cur) => {
        let keys = {}
        if (typeof (obj[cur]) === "object") {
            if (Array.isArray(obj[cur])) {
                if (cur === "ShowEntireReport") {
                    if (queryState.entireDetails) (
                        ans = ans.concat(crushColumnObj(obj[cur]))
                    )
                }
                else {
                    keys["title"] = (cur[0].toUpperCase() + cur.slice(1)).replace(/[A-Z]/g, ' $&').trim();
                    keys["children"] = crushColumnObj(obj[cur])
                    ans = ans.concat([keys])
                }

            }
            else {
                ans = ans.concat(crushColumnObj(obj[cur]))
            }
        }
        else {
            keys["title"] = (obj[cur][0].toUpperCase() + obj[cur].slice(1)).replace(/[A-Z]/g, ' $&').trim();
            keys["dataIndex"] = obj[cur]
            keys["key"] = obj[cur]
            if (["Decision", "transactionDate", "RuleTriggered", "orderId", "firstName", "lastName", "addressline1", "DecisionDescription", "productType"].includes(obj[cur])) {
                keys = { ...keys, ...getColumnSearchProps(obj[cur]) }
            }
            if (obj[cur] === "orderReferenceNumber") {
                keys["title"] = "Order Reference No"
                keys['width'] = '160px'
                keys['sorter'] = (record1, record2) => record1.ReferenceNumber > record2.ReferenceNumber
                keys['sortDirections'] = ['descend']
                keys = { ...keys, ...getColumnSearchProps(obj[cur]) }
            }
            else if (obj[cur] === 'DecisionDescription') {
                keys['width'] = '550px'
            }
            else if (obj[cur] === 'mid') {
                keys['width'] = '110px'
            }
            else if (obj[cur] === 'ReferenceNumber') {
                keys["title"] = "Reference No"
                keys['width'] = '110px'
                keys = { ...keys, ...getColumnSearchProps(obj[cur]) }
            }
            else if (obj[cur] === 'source') {
                keys['width'] = '110px'
                keys = { ...keys, ...getColumnSearchProps(obj[cur]) }
            }
            else if(obj[cur] === 'city') {
                keys['width'] = '120px'
            }
            else {
                keys["width"] = obj[cur].length * 9 + 18
            }
            ans = ans.concat([keys])
        }
        return ans
    }, {}
    );

    const columns = () => {
        if (queryState.isReportData) {
            const column = crushColumnObj(queryState.tableColumnKeys)
            return column
        }
        else { return [] }
    };

    const dataSource = () => {
        if(queryState.isReportData && queryState.flattenedData!==undefined){
            // console.log('data :: ', JSON.stringify(queryState.flattenedData))
            const reportdata = queryState.flattenedData.map((record,index)=>{
              let keys = {}
              keys['key'] = index
              queryState.tableColumnKeys.map((key)=>{
                if(typeof(key)==="string"){
                  if(key==='ReferenceNumber'){
                    keys[key] = record[key]===undefined?("NOREF0000000"):(record[key])
                  }
                  else if(key==='RuleTriggered'){
                    keys[key] = record[key]===undefined?("No Rule"):(record[key])
                  }
                  else if(key==='transactionDate'){
                    keys[key] = moment(record[key]).utcOffset(0).format('YYYY-MM-DD HH:mm:ss.SS')
                  }
                  else{
                    keys[key] = record[key]===undefined||record[key]===""?("NA"):(record[key])
                  }
                }
                if(typeof(key)==="object"){
                  Object.values(key)[0].map((innerkey)=>{
                    keys[innerkey] = record[innerkey]===undefined||record[innerkey]===""?("NA"):(record[innerkey])
                  })
                }
              })
              return keys
            })
            return reportdata
          }else{return []}
    }
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <div className="no-ele">
            <Row style={{ margin: "0.4em 0em 0em 0em", padding: "0.4em 0.8em 0.4em 0.8em", backgroundColor: "#0c3560" }} >
                <Col span={22}>
                    <Space direction="horizontal">
                        <DatePicker onChange={e =>
                            fromDate(e)}
                            showTime={{
                                format: 'HH:mm',
                            }}
                            format={dateFormat}
                            size="middle"
                            placeholder="Select From Date"
                            value={queryState.fromDate ? (moment(queryState.fromDate, dateFormat)) : ('')}
                            disabledDate={(current) => { return current > moment() || current >= moment(queryState.toDate, "YYYY-MM-DD").add(1, "day") || current <= moment(queryState.toDate, "YYYY-MM-DD HH:mm").subtract(3, "month").add(331, "minutes") }}
                        />
                        <DatePicker onChange={e =>
                            toDate(e)}
                            showTime={{
                                format: 'HH:mm',
                            }}
                            format={dateFormat}
                            size="middle"
                            placeholder="Select To Date"
                            value={queryState.toDate ? (moment(queryState.toDate, dateFormat)) : ('')}
                            disabledDate={(current) => { return current > moment() || moment(queryState.fromDate, "YYYY-MM-DD") > current || moment(queryState.fromDate, "YYYY-MM-DD HH:mm").add(3, "month").add(330, "minutes") <= current }}
                        />
                        {/* <DropDownMenu /> */}
                        <Checkbox onChange={e => entireReportDetails(e)} checked={queryState.entireDetailsFlag ? (queryState.entireDetailsFlag) : ('')}>Show Entire Report</Checkbox>
                        <Button type="primary" size="middle" style={{ background: "#ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e" }} onClick={e => submitReportNew(e)}>Fetch Report</Button>
                    </Space>
                </Col>
                <Col span={2} >
                    <Row justify="end">
                        <Button type="primary" size="middle" style={{ background: "#ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e" }} icon={<DownloadOutlined style={{ display: "inline-flex" }} />} onClick={e => exportReportData(e)} disabled={queryState.isLoad}>Export</Button>
                        {/* <Button type="primary" size="middle" style={{ background: "#ed1c2e", backgroundColor: "#ed1c2e", borderColor: "#ed1c2e" }} icon={<DownloadOutlined style={{ display: "inline-flex" }} />} onClick={e => exportReportData(e)} >Export</Button> */}
                    </Row>
                </Col>
            </Row>
            {queryState.isErrorReport && !queryState.isReportData ? (
                <Row>
                    <Col span={24}>
                        <div style={{ display: "block", marginBottom: "0.8em" }}>
                            <Alert
                                message={queryState.reportError} 
                                type="error"
                                closable
                            />
                        </div>
                    </Col>
                </Row>            
            ): ("")}
            <Row>
                <Col span={4} style={{ paddingTop: "26px", paddingRight: "4px" }}>
                    {queryState.isReportData ? (
                        queryState.isLoad  ? (
                            <TreeMenu
                                data={treeData}
                            >

                            </TreeMenu>
                        ): (
                            <TreeMenu
                                data={treeData}
                                onClickItem={({ key, label }) => {
                                    onFilterClicked(key)
                                }}
                            >

                            </TreeMenu>
                        )
                        
                    ): ("")}
                    
                </Col>
                <Col span={20} >
                    <React.Fragment>
                        {queryState.isSpin ? (
                            <div className="spin-ele">
                                <Spin />
                            </div>

                        ) : ([])}
                        {queryState.isReportData ? (
                            <div>
                                {summaryFilter ? (
                                    <div className="spin-ele2">
                                        <span style={{ fontWeight: "bold" }}>Showing {filteredRecordsCount} Records</span>
                                    </div>
                                ): (
                                    <div className="spin-ele2">
                                        <span style={{ fontWeight: "bold" }}>Showing {queryState.totalRecordsCount} Records</span>
                                        {queryState.isLoad ? (
                                            <Spin indicator={antIcon} />
                                        ) : ([])}
                                    </div>
                                )}
                                
                                <Table
                                    dataSource={reportdatanew ? (reportdatanew) : ([])}
                                    columns={queryState.isReportData ? (columns()) : ([])}
                                    scroll={{ y: queryState.reportDropdown ? ('55vh') : ("65vh") }}
                                    size="small"
                                    bordered="true"
                                    pagination={{ showSizeChanger: "true", defaultPageSize: 50 }}
                                    onChange={onChange}
                                />
                            </div>
                        ) : ([])}
                    </React.Fragment>
                </Col>
            </Row>
        </div>
    )
}
export default Summary
