import Swal from 'sweetalert2';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../utils/index"
import Cookies from 'js-cookie';
import { sleep } from '../utils/index'
import axios from "axios";
const API_RETRY_DELAY = 1000;

class APICall {

    async request(URL, requestOptions = {}) {
        // console.log("API CALL Started!!!", URL)
        let code = Cookies.get('code');
        // code = "1234";
        if (code) {
            try {
                //Setting headers
                let headers = {
                    "code": Cookies.get('code'),
                    'code_verifier': Cookies.get('code_verifier'),
                    'applicationId': process.env.REACT_APP_APPLICATION_ID,
                    "x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": '*',
                };
                // let headers = {
                //     "code": "349d4bed-0e71-4604-b8bd-709dae1ab1f4",
                //     'code_verifier': "0067017311490000097049008500200003000",
                //     'applicationId': "kyc-compliance-auth-ui",
                //     "x-origin-refer": "dev-6jxl81wjte-rAWS0i4MiUtHImW1jIA8pAhLaAmmdR7SmTgOBhmEesp5",
                //     "Content-Type": "application/json",
                //     "Access-Control-Allow-Origin": '*',
                // };

                if (requestOptions.headers) {
                    requestOptions.headers = { ...requestOptions.headers, ...headers }
                }
                else
                    requestOptions.headers = headers;

                let response, retryCountLength = 3;
                
                for (let retryCount = 0; retryCount < retryCountLength; retryCount++) {
                    try{
                        response = await axios.get(URL, {headers: requestOptions.headers});
                        if (response.status === 200) {
                            break;
                        }
                        await sleep(API_RETRY_DELAY)
                    } catch (err) {
                        await sleep(API_RETRY_DELAY);
                        if((err.response&&err.response.status&&(err.response.status===403||err.response.status===401))||err.status===403||err.status===401){
                            this.sweetAlert()
                            break;
                        }
                        else if(retryCount===(retryCountLength-1)){
                            throw err;
                        }
                    }
                }
                return response;
            }
            catch (e) {
                // console.log("Error API Call :::", e);
                throw e;
            }

        }
        else {
            this.sweetAlert();
        }
    }

    async postRequest(URL, requestOptions = {}) {
        let code = Cookies.get('code');
        // code = "1234";

        if (code) {
            return new Promise(async (resolve, reject) => {
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID,
                        "Content-Type": "application/json",
                        "x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER
                    };
                    requestOptions.method = "POST"
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;

                    let response = await fetch(URL, requestOptions).catch(e => reject(e));
                    if(response.status===403||response.status===401){
                        this.sweetAlert()
                    }
                    const tempResult = await response.json();
                    // const tempResult = "";

                    // console.log("Response ::: ", response);
                    resolve([tempResult, response.status]);
                    // resolve([tempResult, 400]);
                    // let retryCountLength = 3;
                    // for (let retryCount = 0; retryCount <= retryCountLength; retryCount++) {
                    //     let response = await fetch(URL, requestOptions);
                    //     const tempResult = await response.json();

                    //     console.log("Response ::: ", response);
                    //     resolve([tempResult, response.status]);
                    // //     if (response.status === 200) {
                    // //         resolve(await response.json());
                    // //         break;
                    // //     }
                    // //    await sleep(API_RETRY_DELAY);
                    // }
                    // reject('Retry count exceed');
                }
                catch (e) {
                    console.log("error : ", e);
                    throw e;
                }
            });
        }
        else {
            this.sweetAlert();
            return [{errorMessage:"Session has expired"}, 406]
        }
    }

    async request_search(URL, requestOptions = {}) {
        // console.log("API CALL Started!!!", URL)
        let code = Cookies.get('code');
        // code = "1234";
        if (code) {
            try {
                // Setting headers
                let headers = {
                    // "code": Cookies.get('code'),
                    // 'code_verifier': Cookies.get('code_verifier'),
                    // 'applicationId': process.env.REACT_APP_APPLICATION_ID,
                    // "x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER,
                    "Content-Type": "application/json",
                    // "Access-Control-Allow-Origin": '*',
                };
                // console.log(`Header ${JSON.stringify(headers)}`)

                if (requestOptions.headers) {
                    requestOptions.headers = { ...requestOptions.headers, ...headers }
                }
                else
                    requestOptions.headers = headers;

                // console.log("API CALL request options :::", requestOptions);
                let response, retryCountLength = 3;
                // console.log("Calling fetch!!!");
                // console.log("API CALL URL :::", URL);
                // let response = await axios.get(URL, {
                //     headers: requestOptions.headers
                // });
                
                for (let retryCount = 0; retryCount < retryCountLength; retryCount++) {
                    // console.log("retryCount :: ",retryCount)
                    try{
                        response = await axios.get(URL, {headers: requestOptions.headers});  
                        // await fetch(URL, requestOptions);
                        if (response.status === 200) {
                            break;
                        }
                        await sleep(API_RETRY_DELAY)
                    } catch (err) {
                        await sleep(API_RETRY_DELAY);
                        if((err.response&&err.response.status&&(err.response.status===403||err.response.status===401))||err.status===403||err.status===401){
                            this.sweetAlert()
                            break;
                        }
                        else if(retryCount===(retryCountLength-1)){
                            throw err;
                        }
                    }
                }
                // reject('Retry count exceed');
                // console.log("API CALL result ::: ", response);
                return response;
            }
            catch (e) {
                console.log("Error API Call :::", e);
                throw e;
                // return e;
            }

        }
        else {
            this.sweetAlert();
            // console.log("SweetALert!!!");
        }
    }

    postRequestNew = async (URL, data, requestOptions = {}) => {
        console.log("post endpoint data::", data);
        // const [data, setData]=useState([])

        let code = Cookies.get('code');
        // code = 1234;
        let count = 0;

        if (code) {
            let requestOptions = {
                method: "POST",
                headers: {
                    // "code": Cookies.get('code'),
                    // 'code_verifier': Cookies.get('code_verifier'),
                    // 'applicationId': 'finance-auth-ui',//process.env.REACT_APP_APPLICATION_ID,
                    "Content-Type": "application/json"//,
                    //"x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER
                },
                body: JSON.stringify(data),
            };

            try {
                let pdata = await Promise.all([
                    fetch(
                        URL,
                        requestOptions
                    )
                        .then((Response) => Response.json())
                ]);
                // .then((data) => data);
                // console.log("pdata::::", pdata);
                // count ++;
                return pdata;
            }
            catch (error) {
                console.error(error);
                // message.error("error while posting records");
            }


        }
        else {
            this.sweetAlert();
            // console.log("Upload.js code is not available !!");
        }
    }

    sweetAlert() {
        Swal.fire({
            title: 'Session has expired',
            text: 'Redirecting to the login page',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = `/`;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = `/login`;
            }
        });
    }

    getLoginURL = async () => {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)

        return LOGIN_URL;
    }
}
export default new APICall();
