import Cookies from 'js-cookie';

export const logoutUser = (body) => {
  try {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-origin-refer", process.env.REACT_APP_X_ORIGIN_REFER);

    var requestOptions = {
      method: "POST",
      headers,
      body,
      redirect: "follow",
    };

    return fetch(`${process.env.REACT_APP_API_BASE_PATH}logout`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        Cookies.remove('code');
        Cookies.remove('code_verifier');
        return result;
      })
      .catch((error) => console.log("error", error));
  } catch (err) {
    console.log(err);
  }
};
