import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import "antd/dist/antd.css";
import { Row, Select, Col, DatePicker } from "antd";
import { message, Spin  } from "antd"
import "bootstrap/dist/css/bootstrap.min.css";
import { } from "react-bootstrap";
import { QueryContext } from "../../../context/QueryContext";
import swal from 'sweetalert';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { LoadingOutlined  } from '@ant-design/icons';
import { ResultsDataTable } from "./ResultsDataTable";

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const loader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const QueryPageLayout = () => {

    const { queryState, queryDispatch } = useContext(QueryContext);
    let history = useHistory();

    // const handleGenderChange = e => {
    //     console.log("handleGenderChange : " + e)
    //     const { name, value } = e.target
    //     // console.log("handleGenderChange ::: "+value)
    //     queryDispatch({ type: "GENDER_CHANGE", value: value })
    // }
    function handleProcessSelect(e) {
        // console.log(e)
        queryDispatch({ type: "PROCESS_CONFIG_CHANGE", value: e })
    }
    function handleTitleChange(e) {
        // console.log(e)
        queryDispatch({ type: "TITLE_CHANGE", value: e })
    }
    function handleForenameChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "FORENAME_CHANGE", value: e.target.value })
    }
    function handleSurnameChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "SURNAME_CHANGE", value: e.target.value })
    }
    function handleMiddlenameChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "MIDDLENAME_CHANGE", value: e.target.value })
    }
    function handleSuffixChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "SUFFIX_CHANGE", value: e.target.value })
    }
    function handleCurrFlatNumChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_FLAT_NUM_CHANGE", value: e.target.value })
        // console.log("curr Flat Num : ", queryState.currFlatNum)
    }
    function handleCurrHouseNumChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_HOUSE_NUM_CHANGE", value: e.target.value })
    }
    function handleCurrHouseNameChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_HOUSE_NAME_CHANGE", value: e.target.value })
    }
    function handleCurrPostcodeChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_POSTCODE_CHANGE", value: e.target.value })
    }
    function handleCurrTownChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_TOWN_CHANGE", value: e.target.value })
    }
    function handleCurrDistrictChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_DISTRICT_CHANGE", value: e.target.value })
    }
    function handleCurrCountryChange(e) {
        // console.log(e)
        queryDispatch({ type: "CURR_COUNTRY_CHANGE", value: e })
    }
    function handleCurrStreetChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "CURR_STREET_CHANGE", value: e.target.value })
    }
    function handlePrevFlatNumChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_FLAT_NUM_CHANGE", value: e.target.value })
        // console.log("Prev Flat Num : ", queryState.prevFlatNum)
    }
    function handlePrevHouseNumChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_HOUSE_NUM_CHANGE", value: e.target.value })
        // console.log("Prev House Num : ", queryState.prevHouseNum)
    }
    function handlePrevHouseNameChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_HOUSE_NAME_CHANGE", value: e.target.value })
    }
    function handlePrevPostcodeChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_POSTCODE_CHANGE", value: e.target.value })
    }
    function handlePrevTownChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_TOWN_CHANGE", value: e.target.value })
    }
    function handlePrevDistrictChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_DISTRICT_CHANGE", value: e.target.value })
    }
    function handlePrevCountryChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_COUNTRY_CHANGE", value: e.target.value })
    }
    function handlePrevStreetChange(e) {
        // console.log(e.target.value)
        queryDispatch({ type: "PREV_STREET_CHANGE", value: e.target.value })
    }

    function handleSubmit(e, queryState) {
        e.preventDefault();
        // console.log("Run query")
        try {

            if (queryState.foreName === "" || queryState.surName === "" || queryState.currPostCode === "" || queryState.currTown === "" ||  queryState.currCountry === "") {
                message.error("Please provide input for all mandatory fields !!")
            } else {
                var Name = {}
                var CurrentAddress = {};
                var source = {
                    organization: "Travelex",
                    region: "APAC",
                    branch: "B-1",
                    country: "TR",
                    applicationId: "COMPLIANCE-KYC-UI",
                    source: "COMPLIANCE-KYC-UI"
                }
                var additionaInfo = {};
                var addresses = [];

                var contact = {
                    "webSite": "",
                    "phoneNo": "",
                    "faxNo": "",
                    "email": ""
                };

                Name.title = queryState.title;
                Name.firstName = queryState.foreName;
                Name.middleName = queryState.middleName;
                Name.lastName = queryState.surName;
                Name.nationality = queryState.currCountry;
                Name.suffix = queryState.suffix;
                Name.dateOfBirth = queryState.dob;
                // Name.gender = queryState.gender;


                CurrentAddress.type = "Home";
                CurrentAddress.flatNo = queryState.currFlatNum;

                CurrentAddress.addressline1 = queryState.currHouseNum+","+queryState.currStreet;
                CurrentAddress.addressline2 = queryState.currDistrict;

                CurrentAddress.country = queryState.currCountry;
                CurrentAddress.city = queryState.currTown;
                CurrentAddress.district = queryState.currDistrict;
                CurrentAddress.postalCode = queryState.currPostCode;

                addresses.push(CurrentAddress);
                Name.addresses = addresses;
                Name.contact = contact;
                const data = { "source": source, "customer": Name, "additionaInfo": { "processConfigName": queryState.processConfig } }

                // console.log("data :"+ JSON.stringify(data))

                queryDispatch({ type: "HANDLE_SUBMIT", dispatch: queryDispatch, state: queryState, value: JSON.stringify(data) })
            }


        } catch (error) {
            message.error(error.message);
        }
    }

    const onSuccess = (response) => {
        // console.log("onSuccess");
        // console.log(response);
        // console.log(response.response);

        try {
            response = JSON.parse(response.response)  
        } catch (ex) {

        }
        // console.log(response);
        // console.log(response.DecisionCode);
        // console.log(response.errorMessage);
        let message = response.DecisionCode ? response.DecisionCode : response.errorMessage ? response.errorMessage : "Something went wrong"

        history.push("/results");
        queryDispatch({ type: "RESET_POPUP"})
    }

    const onFailure = (error) => {
        // console.log("On Delete");
        swal({
            title: "EKYC Response",
            text: error,
            icon: "error",
            buttons: {
                // cancel: {
                //     text: "Cancel",
                //     value: false,
                //     visible: true,
                //     className: "",
                //     closeModal: true,
                // },
                confirm: "OK"
            },
        }).then(data => {
            // console.log(data);
            if (data === true){
                queryDispatch({ type: "RESET_POPUP"})
            }
        })
        // history.push("/reports");
        // queryDispatch({ type: "RESET_POPUP"})
    }
    // console.log("Query state ::: ", queryState.dob);
    return (
        <>
            {queryState.isError === true ? (
                onFailure(queryState.errorMessage)
            ) : (<>
                {queryState.isRunQuery === true && queryState.response!==null ? (
                    <>
                        {Object.keys(queryState.response).length === 0 ? ("") : (onSuccess(queryState))}
                    </>
                ): ("")}
                </>
            )}
            <div style={{ minHeight: '78vh', paddingLeft: "12px", backgroundColor: "rgb(249, 251, 255)" }}>
                {/* <Row>
                    <Col>
                        Run Online Query
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Select Process Configuration and return type</b>
                    </Col>
                </Row> */}
                <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "10px 10px 10px 10px" }}>
                    <Col span={4}>
                        Process Configuration
                    </Col>
                    <Col span={4} style={{ color: "grey" }}>
                        <Select
                            name="processConfiguration"
                            style={{ width: "100%" }}
                            placeholder=""
                            optionFilterProp="children"
                            value={queryState.processConfig}
                            onChange={e => handleProcessSelect(e)}

                        >
                            <Option value="" disabled>Select process configuration </Option>
                            <Option key="Authenticate" value="Authenticate">Authenticate</Option>
                            <Option key="AuthenticateV2" value="AuthenticateV2">Authenticate V2</Option>

                        </Select>
                    </Col>
                    <Col span={1}>

                    </Col>
                </Row>

                {queryState.processConfig === "Authenticate" || queryState.processConfig === "AuthenticateV2" ? (
                    <div>

                        <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "5px 5px 5px 5px", marginTop: "10px" }}>
                            <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", width: "100%", color: "white", backgroundColor: "rgba(23, 77, 134, 0.8)", padding:"0px 2px 2px 5px" }}>
                                <Col span={24}>
                                    <b> Name</b>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "5px" }}>
                                <label style={{ width: "20%" }}>Title</label>
                                <label style={{ width: "20%" }}>Forename <span style={{ color: "red" }}>*</span></label>
                                <label style={{ width: "20%" }}>Middle Name</label>
                                <label style={{ width: "20%" }}>Surname <span style={{ color: "red" }}>*</span></label>
                                <label style={{ width: "20%" }}>Suffix</label>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "5px" }}>
                                <div style={{ width: "20%" }}>
                                    <Select style={{ width: "100%", paddingRight: "5px" }} optionFilterProp="children" onChange={e => handleTitleChange(e)} value={queryState.title}>
                                        <Option key="Mr" value="Mr.">Mr.</Option>
                                        <Option key="Mrs" value="Mrs.">Mrs.</Option>
                                        <Option key="Miss" value="Miss.">Miss</Option>
                                        <Option key="MS" value="MS">Ms</Option>
                                        <Option key="DR" value="DR">Dr</Option>
                                        <Option key="PROFESSOR" value="PROFESSOR">Professor</Option>
                                        <Option key="REV" value="REV">Rev</Option>
                                    </Select>
                                </div>
                                <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <input type="text" id="forename" placeholder="Forename" name="forename" value={queryState.foreName} onChange={e => handleForenameChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                </div>
                                <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <input type="text" id="middlename" placeholder="Middle Name" name="middlename" value={queryState.middleName} onChange={e => handleMiddlenameChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                </div>
                                <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <input type="text" id="surname" placeholder="Surname" name="surname" value={queryState.surName} onChange={e => handleSurnameChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                </div>
                                <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <input type="text" id="suffix" placeholder="suffix" name="suffix" value={queryState.suffix} onChange={e => handleSuffixChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                </div>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "5px" }}>
                                <label style={{ width: "20%" }}>Date of Birth</label>
                                {/* <label style={{ width: "20%" }}>Gender</label> */}
                            </Row>
                            <Row style={{ width: "100%", marginTop: "5px" }}>
                                <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <DatePicker style={{ width: "100%" }}
                                        // value={queryState.dob{queryState.dob ? (moment(queryState.dob, dateFormat)): ("")}}
                                        value={queryState.dob? (moment(queryState.dob, "YYYY-MM-DD")): ("")}
                                        format={dateFormat}
                                        onChange={(e) => queryDispatch({ type: "DOB_CHANGE", value: e })}
                                    />
                                </div>
                                {/* <div style={{ width: "20%", paddingRight: "5px" }}>
                                    <input type="radio" id="male" name="male" value="male" onChange={handleGenderChange} checked={queryState.gender === "male"} />  Male
                                    <input type="radio" id="female" name="female" value="female" onChange={handleGenderChange} style={{ marginLeft: "5px" }} checked={queryState.gender === "female"} />  Female
                                </div> */}
                            </Row>
                        </Row>

                        <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "5px 5px 5px 5px", marginTop: "10px" }}>
                            <div style={{ width: "49.5%", borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "2px 2px 2px 2px" }}>
                                <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", width: "100%", padding: "0px 2px 2px 5px", color: "white", backgroundColor: "rgba(23, 77, 134, 0.8)" }}>
                                    <Col span={24}>
                                        <b>Current Address</b>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Flat Number <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currAddrFlatNum" placeholder="Flat Number" name="currAddrFlatNum" value={queryState.currFlatNum} onChange={e => handleCurrFlatNumChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>

                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>House Name <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currAddrHouseName" placeholder="House Name" name="currAddrHouseName" value={queryState.currHouseName} onChange={e => handleCurrHouseNameChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>House Number <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currHouseNum" placeholder="House Number" name="currHouseNum" value={queryState.currHouseNum} onChange={e => handleCurrHouseNumChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Street</label>
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        <input type="text" id="currStreet" placeholder="Street" name="currStreet" value={queryState.currStreet} onChange={e => handleCurrStreetChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>
                                    <div style={{ width: "50%" }}>

                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>District</label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currDistrict" placeholder="District" name="currDistrict" value={queryState.currDistrict} onChange={e => handleCurrDistrictChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>

                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Town <span style={{ color: "red" }}>*</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currTown" placeholder="Town" name="currTown" value={queryState.currTown} onChange={e => handleCurrTownChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Post Code <span style={{ color: "red" }}>*</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input type="text" id="currPostCode" placeholder="Post Code" name="currPostCode" value={queryState.currPostCode} onChange={e => handleCurrPostcodeChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>
                                    

                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Country<span style={{ color: "red" }}>*</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <Select
                                            name="currCountry"
                                            style={{ borderColor: "#dfdfe6", borderWidth: "1px", width: "58%" }}
                                            placeholder=""
                                            optionFilterProp="children"
                                            defaultValue={"GB"}
                                            value={queryState.currCountry}
                                            onChange={e => handleCurrCountryChange(e)}

                                        >
                                            <Option value="" disabled>Country </Option>
                                            <Option key="GB" value="GB">United Kingdom</Option>
                                            <Option key="FR" value="FR">France</Option>

                                        </Select>
                                    </div>


                                </Row>
                            </div>
                            <div style={{ width: "1%" }}>

                            </div>
                            <div style={{ width: "49.5%", borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", padding: "2px 2px 2px 2px" }}>
                                <Row style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#c8c8d2", width: "100%", padding: "0px 2px 2px 5px", color: "white", backgroundColor: "rgba(23, 77, 134, 0.8)" }}>
                                    <Col span={24}>
                                        <b>Previous Address</b>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Flat Number <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevAddrFlatNum" placeholder="Flat Number" name="prevAddrFlatNum" onChange={e => handlePrevFlatNumChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>House Name <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevHouseName" placeholder="House Name" name="prevHouseName" onChange={e => handlePrevHouseNameChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>House Number <span style={{ color: "#0a5ea8" }}>**</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevHouseNum" placeholder="House Number" name="prevHouseNum" onChange={e => handlePrevHouseNumChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Street</label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevStreet" placeholder="Street" name="prevStreet" onChange={e => handlePrevStreetChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>District</label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevDistrict" placeholder="District" name="prevDistrict" onChange={e => handlePrevDistrictChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Town <span style={{ color: "red" }}>*</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevTown" placeholder="Town" name="prevTown" onChange={e => handlePrevTownChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Post Code <span style={{ color: "red" }}>*</span></label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevPostCode" placeholder="Post Code" name="prevPostCode" onChange={e => handlePrevPostcodeChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>

                                </Row>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "50%" }}>
                                        <label>Country</label>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <input disabled type="text" id="prevCountry" placeholder="Country" name="prevCountry" onChange={e => handlePrevCountryChange(e)} style={{ borderColor: "#dfdfe6", borderWidth: "1px" }} />
                                    </div>


                                </Row>
                            </div>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col span={4}>
                                <Button variant="primary" size="sm" style={{ width: 198, paddingRight: "5px" }} onClick={(e) => queryDispatch({ type: "CLEAR_BUTTON_CLICK", value: e })} >Clear All</Button>
                            </Col>
                            <Col span={2}>
                                {queryState.isLoading ? (
                                    <Spin style={{paddingLeft: "30px"}} indicator={loader} />
                                ): ("")}
                            </Col>
                            <Col span={4}>
                                <Button variant="primary" size="sm" style={{ width: 198, background: "#00802b", borderColor: "#00802b" }} onClick={(e) => handleSubmit(e, queryState)} >Run Query</Button>
                            </Col>
                            <Col span={3}>
                            </Col>
                            <Col span={11}>
                                <p style={{ color: "red", marginBottom: "0px", marginToptop: "0em" }}>* Mandatory Fields</p>
                                <p style={{ color: "#0a5ea8", marginBottom: "0px" }}>** One or more of these fields must be populated</p>
                                <p style={{ color: "#0a5ea8", marginBottom: "0px" }}>*** Mandatory fields must be populated if previous address is provided.</p>
                            </Col>
                            <Col>
                            </Col>


                        </Row>

                    </div>
                ) : ("")}

            </div>
        </>
    )
}

export default QueryPageLayout

